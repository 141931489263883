<template>
  <div class=container>
      <!-- <div class="wrapper"> -->
            <h2> Biography</h2>
            <p>Shree K. Nayar is the T. C. Chang Professor of Computer Science at Columbia University. He heads the
                Columbia Vision Laboratory (<a class=list-element href="https://www.cs.columbia.edu/CAVE/">CAVE</a>), which develops computational imaging and computer vision systems. His
                research is focused on three areas - the creation of novel cameras that provide new forms of visual
                information, the design of physics-based models for vision and graphics, and the development of
                algorithms for understanding scenes from images. His work is motivated by applications in the fields of
                imaging, computer vision, robotics, virtual reality, augmented reality, visual communication, computer
                graphics and human-computer interfaces.</p>
            <p>Nayar received his PhD degree in Electrical and Computer Engineering from the Robotics Institute at
                Carnegie Mellon University. For his research and teaching he has received several honors including the
                David Marr Prize (1990 and 1995), the David and Lucile Packard Fellowship (1992), the National Young
                Investigator Award (1993), the NTT Distinguished Scientific Achievement Award (1994), the Keck
                Foundation Award for Excellence in Teaching (1995), the Columbia Great Teacher Award (2006), the
                Carnegie Mellon Alumni Achievement Award (2009), Sony Appreciation Honor (2014), the Columbia
                Engineering Distinguished Faculty Teaching Award (2015), and the IEEE PAMI Distinguished Researcher
                Award (2019).</p>
            <p>Nayar received his PhD degree in Electrical and Computer Engineering from the Robotics Institute at Carnegie Mellon University. For his research and teaching he has received several honors including the David Marr Prize (1990 and 1995), the David and Lucile Packard Fellowship (1992), the National Young Investigator Award (1993), the NTT Distinguished Scientific Achievement Award (1994), the Keck Foundation Award for Excellence in Teaching (1995), the Columbia Great Teacher Award (2006), the Carnegie Mellon Alumni Achievement Award (2009), Sony Appreciation Honor (2014), the Columbia Engineering Distinguished Faculty Teaching Award (2015), the IEEE PAMI Distinguished Researcher Award (2019), and the Funai Achievement Award (2021).</p>
            <p>For his contributions to computer vision and computational imaging, he was elected to the National
                Academy of Engineering in 2008, the American Academy of Arts and Sciences in 2011, and the National
                Academy of Inventors in 2014.</p>
        <!-- </div> -->
    </div>
</template>

<script scoped>
export default {

}
</script>

<style scoped>
.container{
    min-height: 100vh;
    width:1000px;
    margin-left:auto;
    margin-right: auto;
    display:block;
}
.list-element{
    color:black;
    text-decoration: none;
}
.list-element:hover{
    color:rgba(103, 103, 104, 0.842)
}
h2{
    padding-top:30px;
            font-family:'Raleway', sans-serif;
        font-size: 30px;
        width:100%;
        text-align: left;
        font-weight: 600;
}
</style>